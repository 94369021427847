





































































































































































































































































































import { Route } from 'vue-router'
import { capitalize } from '@/helpers/text'
import { stateModule, projectModule } from '@/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FlexibleDocumentService } from '@/services/flexibleDocument'
import { MultipleDocumentsService } from '@/services/multipleDocuments'

import { getTocIndex } from '@/helpers/FlexibleDocument'

import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentStatusResource } from '@/models/status/FlexibleDocumentStatusResource'
import { FlexibleDocumentPermissions, FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentItemCollection } from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import { FlexibleDocumentDropdownOptionResource } from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'

import { FlexibleDocumentUpdateRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateRequest'
import { FlexibleDocumentUpdateStatusRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateStatusRequest'
import { FlexibleDocumentCreateTemplateRequest } from '@/requests/flexibleDocument/FlexibleDocumentCreateTemplateRequest'
import { FlexibleDocumentUpdateExportSettingsRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateExportSettingsRequest'

import Chapter from './partials/Chapter.vue'
import TitlePage from './partials/TitlePage.vue'
import TextInput from '@/components/inputs/Text.vue'
import DateInput from '@/components/inputs/Date.vue'
import Submit from '@/components/project/Submit.vue'
import RadioInput from '@/components/inputs/Radio.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DefaultModal from '@/components/modals/Default.vue'
import CommentBox from '@/components/inputs/CommentBox.vue'
import WysiwygInput from '@/components/editors/Wysiwyg.vue'
import CheckboxInput from '@/components/inputs/Checkbox.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import NewItemDropdown from './partials/NewItemDropdown.vue'
import TextareaInput from '@/components/inputs/Textarea.vue'
import StatusPicker from '@/components/inputs/StatusPicker.vue'
import StatusButton from '@/components/buttons/StatusButton.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import UsersDropdown from '@/components/inputs/dropdowns/UsersDropdown.vue'

import Sidebar from './partials/Sidebar.vue'
import {FlexibleDocumentReviewStatusRequest, ReviewFeedback} from '@/requests/flexibleDocument/FlexibleDocumentReviewStatusRequest'
import StatusBadge from '@/components/statuses/StatusBadge.vue'

type ClonedRoute = Route

@Component({
  components: {
    RadioInput,
    StatusBadge,
    CommentBox,
    UsersDropdown, WysiwygInput,
    Submit, Sidebar, Chapter, TitlePage, TextInput, DateInput, SwitchInput, StatusPicker, DefaultModal, StatusButton, CheckboxInput, DropdownInput, ResponseModal, TextareaInput, NewItemDropdown
  }
})
export default class Index extends Vue {

  @Prop() private readonly project!: ProjectResource

  private documentId: string = this.$route.params.template_id
  private indexOpen: boolean = true
  private documentScrollPos: number = 0
  private flexibleDocument: FlexibleDocumentResource | null = null
  private flexibleDocumentService: FlexibleDocumentService = new FlexibleDocumentService({ project_id: this.project.id })
  private multipleDocumentService: MultipleDocumentsService = new MultipleDocumentsService({ project_id: this.project.id })
  private dropdownOptions: FlexibleDocumentDropdownOptionResource[] = []
  private exportSettingsTooltip: string = 'These settings will be used throughout this project. Other people in your team will export this document with the same settings.'

  // Start flexible document empty or with template
  private chooseTemplateModal: { open: boolean, form: FlexibleDocumentCreateTemplateRequest, errors: ErrorResponse, loading: boolean } = {
    open: false, loading: false, errors: {}, form: new FlexibleDocumentCreateTemplateRequest()
  }

  // Update flexible document modal
  private settingsModal: { isSubmitting: boolean, open: boolean, form: FlexibleDocumentUpdateRequest, exportForm: null | FlexibleDocumentUpdateExportSettingsRequest, errors: ErrorResponse } = {
    isSubmitting: false,
    open: false,
    form: new FlexibleDocumentUpdateRequest(),
    exportForm: null,
    errors: {}
  }

  // Submit modal for flexible document status change
  private changeStatusModal: { open: boolean, status: FlexibleDocumentStatusResource | null, loading: boolean, form: FlexibleDocumentUpdateStatusRequest, errors: ErrorResponse } = {
    open: false,
    status: null,
    loading: false,
    errors: {},
    form: new FlexibleDocumentUpdateStatusRequest()
  }

  private reviewStatusModal: { open: boolean, loading: boolean, form: FlexibleDocumentReviewStatusRequest, errors: ErrorResponse } = {
    open: false,
    loading: false,
    errors: {},
    form: new FlexibleDocumentReviewStatusRequest()
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get filteredStatusUsers() {
    return (this.changeStatusModal.status?.need_allocation?.length ?? 0) > 0 ? this.projectUsers.filter((user) => this.changeStatusModal.status?.need_allocation?.includes(user.project_role)) : []
  }

  private get mappedStatusUsers(): SelectItem[] {
    return this.filteredStatusUsers.map(({ id, name }) => ({ value: id, label: name  }))
  }

  private get sideOpen(): boolean {
    const pages = ['projects-flexible-document-details', 'projects-flexible-document-comments', 'projects-flexible-document-activity', 'projects-flexible-document-element', 'projects-flexible-document-proposal']
    return pages.includes(this.$route.name ?? '')
  }

  private get isEditingOrDetailsOpen(): boolean {
    return !!this.$route.query.editing || !!this.$route.params.element_id
  }

  private get canSubmitStatusForm(): boolean {
    if (this.changeStatusModal.status?.review_meta) {
      return this.project.canSubmitMeta
    } else {
      return true
    }
  }

  private getTocIndex(item: FlexibleDocumentItemCollection, collection: FlexibleDocumentItemCollection[]): string {
    const index = getTocIndex(item, collection)
    return index > -1 ? `${index + 1}.` : ''
  }

  private canPerformAction(key: FlexibleDocumentPermissions): boolean {
    return this.flexibleDocument?.canPerformAction(key) ?? false
  }

  private getComponent(type: string): string {
    switch (type) {
      case 'dropdown':
      case 'Dropdown':
        return 'DropdownInput'
      case 'date':
        return 'DateInput'
      case 'wysiwyg':
        return 'TextareaInput'
      default:
        return 'TextInput'
    }
  }

  @Watch('$route')
  private onRouteChange(val: ClonedRoute) {
    // Scroll element into view
    if (val.name === 'projects-flexible-document-element') {
      this.$nextTick(() => {
        const el = document.getElementById(val.params.element_id)
        if (el) this.scrollIntoView(el)
      })
      return
    }
  }

  private get changeStatusModalSize(): string {
    return this.changeStatusModal.status?.review_meta ? 'large' : 'medium'
  }

  private get items(): FlexibleDocumentItemCollection[] {
    return this.flexibleDocument?.items ?? []
  }

  private get itemsFlat(): FlexibleDocumentItemCollection[] {
    return this.flexibleDocument?.itemsFlat ?? []
  }

  private mounted() {
    this.project.getMetaOptions()
    
    sessionStorage.setItem('currentDocumentId', this.documentId)
    this.getInitialData()

    this.$root.$on('refreshDocumentHard', () => {
      this.documentScrollPos = document.querySelector('.document-wrapper')?.scrollTop || 0

      this.flexibleDocument = null
      setTimeout(() => this.getInitialData(), 100)
    })
  }

  private scrollIntoView(el: HTMLElement) {
    el?.scrollIntoView({
      block: 'start', inline: 'center', behavior: 'smooth'
    })
  }

  // Data
  private getInitialData() {
    // We need to wait for nextTick in mounted before beforeRouteEnter next is done and the service is set.
    this.$nextTick(async () => {
      await this.getData()
      this.getDropdownOptions()
    })
  }


  private async getData() {
    stateModule.setLoading(true)
    await this.getDataBackground()
    stateModule.setLoading(false)
  }

  private async getDataBackground() {
    try {
      this.flexibleDocument = await this.getFlexibleDocument()

      this.setSettingsFormData()
      this.setExportSettingsFormData()
    } catch (e) {
      console.error(e)
    } finally {


      const element_id = this.$route.params.element_id || this.$route.query.editing || this.$route.query.suggesting
      if (typeof element_id === 'string') {
        this.$nextTick(() => {
          const el = document.getElementById(element_id)
          if (el) this.scrollIntoView(el)
        })
      }
      else {
        setTimeout(() => {
          document.querySelector('.document-wrapper')?.scrollTo(0, this.documentScrollPos)
        })
      }
    }
  }

  private async getFlexibleDocument(): Promise<FlexibleDocumentResource> {
    const { data } = await this.multipleDocumentService.get(this.documentId)
    return data
  }

  private startUpdateStatusProcess(statusValue: string) {
    const status = this.flexibleDocument?.statuses.find(({ value }) => statusValue === value) ?? null

    if (status && this.flexibleDocument?.status.value !== status.value) {
      this.changeStatusModal.status = status
      this.$set(this.changeStatusModal, 'form', new FlexibleDocumentUpdateStatusRequest(status))
      this.changeStatusModal.open = true
    }
  }

  private closeChangeStatusModal() {
    this.changeStatusModal.open = false
    this.project.resetMetaValues()
  }

  private async patchStatus() {
    if (this.flexibleDocument && this.changeStatusModal.status && this.changeStatusModal.status.value) {
      this.changeStatusModal.loading = true
      this.changeStatusModal.errors = {}
      try {
        await this.flexibleDocument.patchStatus(this.changeStatusModal.form)
        if (this.changeStatusModal.status.review_meta) {
          await this.project.patchMeta()
        }
        this.changeStatusModal.open = false
        this.getData()
      } catch (e) {
        console.error(e)
        if (e.errors) {
          this.changeStatusModal.errors = e.errors
        }
      } finally {
        this.changeStatusModal.loading = false
      }
    }
  }

  private startReviewStatusProcess(feedback: ReviewFeedback) {
    this.reviewStatusModal.form.status = feedback
    this.reviewStatusModal.open = true
  }

  private async reviewStatus() {
    if (!this.flexibleDocument) return
    this.reviewStatusModal.loading = true
    try {
      await this.flexibleDocument.reviewStatus(this.reviewStatusModal.form)
      this.reviewStatusModal.open = false
    } catch (e) {
      console.error(e)
    } finally {
      this.reviewStatusModal.loading = false
    }
  }

  private async getDropdownOptions() {
    const { data } = await this.multipleDocumentService.getDropdownOptions()
    this.dropdownOptions = data
  }

  private setSettingsFormData() {
    if (this.flexibleDocument) {
      this.settingsModal.form = new FlexibleDocumentUpdateRequest(this.flexibleDocument)
    }
  }

  private setExportSettingsFormData() {
    if (this.flexibleDocument) {
      this.settingsModal.exportForm = new FlexibleDocumentUpdateExportSettingsRequest(this.flexibleDocument)
    }
  }


  private toggleIndex() {
    this.indexOpen = !this.indexOpen
  }

  // Settings modal
  private toggleSettingsModal() {
    this.settingsModal.open = !this.settingsModal.open
  }

  private closeSettingsModal() {
    this.settingsModal.open = false
    this.setSettingsFormData()
    this.setExportSettingsFormData()
    this.project.resetMetaValues()
  }

  private async submitSettingsModal() {
    if (!this.settingsModal.exportForm || !this.flexibleDocument) return
    this.$set(this.settingsModal, 'errors', {})

    this.settingsModal.isSubmitting = true
    try {
      await Promise.all([
        this.flexibleDocument.patch(this.settingsModal.form),
        this.flexibleDocument.updateExportSettings(this.settingsModal.exportForm),
        this.project.patchMeta()
      ])

      this.settingsModal.open = false
    } catch ({ errors }) {
      if (errors) {
        this.$set(this.settingsModal, 'errors', errors)
      }
    } finally {
      this.settingsModal.isSubmitting = false
    }
  }


  private capitalize(s: string): string {
    return capitalize(s)
  }

}
